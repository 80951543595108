import { useState } from "react";
import { DragDropContext, Droppable, Draggable, ResponderProvided, DropResult } from "react-beautiful-dnd";
import { List, IconButton } from "@material-ui/core";
import { Queue, Close } from "@material-ui/icons";

import { Layer, LayerVariant } from "../types";
import { LayerItem } from "../components/layer/LayerItem";
import { PanelDrawer, PanelDrawerHeader, PanelDrawerBody } from "../components/drawer/PanelDrawer";
import { TreeDrawer } from "../components/drawer/TreeDrawer";
import { TreeViewer } from "../components/layer/TreeViewer";
import { useLayers } from "../hooks/useLayers";

interface Props {
  isTreeManaged: boolean | undefined;
}

export const LayersPage = (props: Props) => {
  const { isTreeManaged } = props;
  const { layers, activeLayers, toggleLayer, reorder, setVariant, updateOpacity } = useLayers(isTreeManaged === true);
  const [selectedLayer, setSelectedLayer] = useState<Layer | null>(null);
  const [openTree, setOpenTree] = useState<boolean>(false);

  function handleLayerOpacityChange(layer: Layer, opacity: number) {
    updateOpacity(layer, opacity);
  }

  function handleDragEnd(result: DropResult, provided: ResponderProvided) {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    reorder(source.index, destination.index);
  }

  function handleLayerClick(layer: Layer) {
    if (selectedLayer?.id !== layer.id) setSelectedLayer(layer);
    else setSelectedLayer(null);
  }

  // render the component
  return render();

  function render() {
    return (
      <>
        <PanelDrawer variant="permanent">
          <PanelDrawerHeader>
            Layers
            {!!isTreeManaged && (
              <IconButton edge="end" onClick={() => setOpenTree(!openTree)}>
                <Queue />
              </IconButton>
            )}
          </PanelDrawerHeader>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-layers">
              {(provided) => (
                <List ref={provided.innerRef} {...provided.droppableProps}>
                  {(isTreeManaged !== true ? layers : activeLayers).map((layer: Layer, index: number) => (
                    <Draggable
                      draggableId={layer.id.toString()}
                      index={index}
                      key={layer.id}
                      isDragDisabled={selectedLayer?.id === layer.id}
                    >
                      {({ draggableProps, dragHandleProps, innerRef }) => (
                        <LayerItem
                          ref={innerRef}
                          {...draggableProps}
                          selected={selectedLayer?.id === layer.id}
                          layer={layer}
                          isTreeManaged={isTreeManaged}
                          onClick={() => handleLayerClick(layer)}
                          onToggleVisibility={() => toggleLayer(layer)}
                          onChangeOpacity={(value) => handleLayerOpacityChange(layer, value)}
                          onChangeVariant={(layer: Layer, variant: LayerVariant) => setVariant(layer, variant)}
                          draghandleProps={dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </PanelDrawer>
        <TreeDrawer open={openTree} variant="temporary" onClose={() => setOpenTree(false)}>
          <PanelDrawerHeader>
            Select layers to add
            <IconButton edge="end" onClick={() => setOpenTree(!openTree)}>
              <Close />
            </IconButton>
          </PanelDrawerHeader>
          <PanelDrawerBody>
            <TreeViewer layers={layers} />
          </PanelDrawerBody>
        </TreeDrawer>
      </>
    );
  }
};
