import { forwardRef, useMemo } from "react";
import { Layer, LayerVariant } from "../../types";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  DragIndicator as DragIndicatorIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Delete,
} from "@material-ui/icons";
import {
  ButtonBaseProps,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import styled from "styled-components";

import { useMapPosition } from "../../hooks/useMapPosition";
import { getVariant } from "../../utils/layer.utils";
import { LayerItemDetails } from "./LayerItemDetails";

type Props = ListItemProps<"div"> &
  ButtonBaseProps<"div"> & {
    onToggleVisibility: () => void;
    onChangeOpacity: (opacity: number) => void;
    onChangeVariant: (layer: Layer, variant: LayerVariant) => void;
    layer: Layer;
    draghandleProps: DraggableProvidedDragHandleProps | null;
    isTreeManaged: boolean | undefined;
  };

export const LayerItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { layer, isTreeManaged, onToggleVisibility, onChangeVariant, onChangeOpacity, draghandleProps, ...rest } =
    props;

  const mapPosition = useMapPosition();

  const activeVariant = useMemo(() => {
    return getVariant(layer);
  }, [layer]);

  /**
   * Functions
   */

  // render the component
  return render();

  function render() {
    return (
      <>
        <StyledListItem ref={ref} {...rest} button className={layer.isActive ? "isActive" : undefined}>
          {/* TODO: upgrade react-beautiful-dnd to fix this? */}
          {/*  @ts-ignore */}
          <ListItemIcon className="dragHandle" {...draghandleProps}>
            <DragIndicatorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            className="header"
            title={layer.title}
            primary={layer.title}
            primaryTypographyProps={{ noWrap: true }}
            secondary={
              <>
                {mapPosition.zoom < (layer.wms.minZoom || 0) && (
                  <div className="status">Zoom in om deze kaart zichtbaar te maken</div>
                )}
                {mapPosition.zoom > (layer.wms.maxZoom || 18) && (
                  <div className="status">Zoom uit om deze kaart zichtbaar te maken</div>
                )}
                {layer.isActive && activeVariant && !rest.selected && (
                  <small className="selectedVariant">
                    {layer.variants?.label && <strong>{layer.variants.label}:</strong>}
                    {activeVariant.label}
                  </small>
                )}
              </>
            }
          />
          <ListItemSecondaryAction>
            <Tooltip title="Visualize" placement="bottom" arrow={true} enterDelay={1000}>
              <StyledIconButton onClick={() => onToggleVisibility()} className="visibility">
                {!!!isTreeManaged && layer.isActive && <VisibilityIcon />}
                {!!!isTreeManaged && !layer.isActive && <VisibilityOffIcon className="off" />}
                {!!isTreeManaged && <Delete />}
              </StyledIconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </StyledListItem>
        <Collapse in={rest.selected} mountOnEnter unmountOnExit component="li">
          <LayerItemDetails
            layer={layer}
            onChangeVariant={props.onChangeVariant}
            onChangeOpacity={props.onChangeOpacity}
          />
        </Collapse>
      </>
    );
  }
});

const StyledIconButton = styled(IconButton)`
  &.visibility:not(:hover) {
    color: ${({ theme }) => `${theme.palette.grey[500]}`};

    .off {
      color: ${({ theme }) => theme.palette.grey[100]};
    }
  }
`;

const StyledListItem = styled(ListItem)`
  &:hover {
    .dragHandle {
      opacity: 1;
    }
  }

  &[style^="pos"] ~ .MuiListItemSecondaryAction-root {
    display: none;
  }

  .selectedVariant {
    display: block;
    color: ${({ theme }) => `${theme.palette.grey[500]}`};
    font-weight: 400;

    strong {
      margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
    }
  }

  .dragHandle {
    transition: all 300ms ease;
    opacity: 0;
    min-width: 24px;

    &:hover {
      color: #a5adb2;
    }
  }
`;
