import { useWFSGetFeature } from "./ogc";
import { useLayers } from "./useLayers";
import { SelectionFeature } from "./useMarker";

export function useSelectionFeatureData(data: SelectionFeature["data"]) {
  const { layers } = useLayers();
  const layer = layers.find((layer) => layer.id === data.layerId);

  return useWFSGetFeature<any>(layer?.wfs?.url, {
    typeNames: layer?.wms.layers,
    // @ts-ignore
    featureid: data.featureId,
  });
}
