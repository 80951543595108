import { ChangeEvent, useState } from "react";
import { isEmpty } from "lodash-es";
import * as nominatimService from "./../../services/nominatim.service";
import * as geopuntService from "./../../services/geopunt.service";
import { Close, Room, Search } from "@material-ui/icons";
import styled from "styled-components";
import {
  ClickAwayListener,
  Divider,
  IconButton,
  InputBase,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
} from "@material-ui/core";

// export type Location = nominatimService.Location;
export interface Location extends nominatimService.Location {}

interface Props {
  className?: string;
  type: "nominatim" | "geopunt";
  onClickOutside: () => void;
  onSearchResultClick: (location: Location) => void;
}

interface State {
  query: string;
  timeout: number;
  results: Array<Location>;
  resultsLength: number;
  resultIndex: number;
  isOpen: boolean;
}

const locationServices = {
  nominatim: nominatimService,
  geopunt: geopuntService,
};

export const LocationSearchBox = (props: Props) => {
  const [state, setState] = useState<State>({
    query: "",
    timeout: -1,
    results: [],
    resultIndex: -1,
    resultsLength: 0,
    isOpen: false,
  });

  function handleClickOutside() {
    setState({ ...state, isOpen: false });
    props.onClickOutside();
  }

  function handleSearchQueryChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    clearTimeout(state.timeout);
    const newTimeout = setTimeout(searchResults, 500, value);

    setState({
      ...state,
      query: value,
      isOpen: true,
      resultIndex: 0,
      timeout: newTimeout,
    });
    if (isEmpty(value)) {
      clearTimeout(newTimeout);
    }
  }

  async function searchResults(query: string) {
    const searchResults = await locationServices[props.type].getLocation(query);

    setState({
      ...state,
      query: query,
      results: searchResults,
      resultsLength: searchResults.length,
    });
  }

  function onSearchResultSelect(location: Location) {
    setState({ ...state, isOpen: false, resultsLength: 0 });
    props.onSearchResultClick(location);
  }

  function clearSearch() {
    setState({ ...state, query: "", results: [], resultsLength: 0 });
  }

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <Styles className={props.className} elevation={2}>
        <form className="composed-input">
          <InputBase
            autoFocus
            fullWidth
            className="search-input"
            placeholder="Search for a location"
            value={state.query}
            onFocus={() => setState({ ...state, isOpen: true })}
            onChange={handleSearchQueryChange}
            inputProps={{ "aria-label": "Search for a location" }}
          />
          {!isEmpty(state.query) && (
            <>
              <IconButton type="submit" aria-label="search" onClick={clearSearch}>
                <Close className="iconClose" />
              </IconButton>
              <Divider orientation="vertical" className="divider" />
            </>
          )}

          <IconButton aria-label="directions" onClick={handleClickOutside}>
            <Search className="iconSearch" />
          </IconButton>
        </form>
        {state.resultsLength > 0 && (
          <MenuList>
            {state.results.map((searchResult, i) => (
              <MenuItem
                key={searchResult.id}
                selected={state.resultIndex === i}
                onClick={() => onSearchResultSelect(searchResult)}
              >
                <ListItemIcon>
                  <Room fontSize="small" className="iconResult" />
                </ListItemIcon>
                {searchResult.displayName}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Styles>
    </ClickAwayListener>
  );
};

const Styles = styled(Paper)`
  width: 345px;
  z-index: 999;

  .composed-input {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing(0.5, 1)};

    .search-input {
      flex-grow: 1;
    }

    .divider {
      height: 28px;
      margin: ${({ theme }) => `${theme.spacing(0.5)}px`};
      color: ${({ theme }) => theme.palette.grey[300]};
    }
  }
`;
