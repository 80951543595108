import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./config/theme";
import history from "./config/history";

import "leaflet/dist/leaflet.css";
import { GlobalStyle } from "./config/GlobalStyle";

const MOUNT_NODE = document.getElementById("root");
const queryClient = new QueryClient();

function render() {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <CssBaseline />
          <GlobalStyle />
          <Router history={history}>
            <Route path="/" component={App} />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </MuiThemeProvider>,
    MOUNT_NODE
  );
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
