import { sortBy } from "lodash";
import { useContext, useMemo } from "react";
import { LayersStateContext } from "../context/layers";
import { Layer, LayerVariant } from "../types";

export function useLayers(isTreeManaged: boolean = false) {
  const [layers, setLayers] = useContext(LayersStateContext);

  const activeLayers = useMemo(() => {
    return layers.filter((layer) => layer.isActive);
  }, [layers]);

  function toggleLayer(updatedLayer: Layer) {
    const updatedLayers = layers.map((layer) => {
      if (layer.id === updatedLayer.id) {
        return { ...updatedLayer, isActive: !updatedLayer.isActive };
      }
      return layer;
    });

    setLayers(updatedLayers);
  }

  function reorder(from: number, to: number) {
    const start = [...(isTreeManaged !== true ? layers : activeLayers)];
    const [removed] = start.splice(from, 1);
    start.splice(to, 0, removed);
    const reordered = sortBy(
      start.map((layer, index) => {
        if (layer.zIndex !== index) return { ...layer, zIndex: index };
        return layer;
      }),
      "zIndex"
    );
    if (isTreeManaged !== true) {
      setLayers(reordered);
    } else {
      setLayers([...reordered, ...layers.filter((layer) => !layer.isActive)]);
    }
  }

  function setVariant(updatedLayer: Layer, variant: LayerVariant) {
    const updatedLayers = layers.map((layer) => {
      if (layer.id === updatedLayer.id) return { ...updatedLayer, activeVariant: variant.key };
      return layer;
    });

    setLayers(updatedLayers);
  }

  function updateOpacity(updatedLayer: Layer, opacity: number) {
    const updatedLayers = layers.map((layer) => {
      if (layer.id === updatedLayer.id) return { ...updatedLayer, opacity };
      return layer;
    });

    setLayers(updatedLayers);
  }

  return {
    layers,
    activeLayers,
    toggleLayer,
    reorder,
    setVariant,
    setLayers,
    updateOpacity,
  };
}
