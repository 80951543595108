import axios from "axios";
// @ts-ignore: no types folder availlable
import jsonpAdapter from "axios-jsonp";

const baseUrl = "https://loc.geopunt.be/v4";

interface getLocationOptions {
  count: number;
}

export interface Location {
  id: string;
  lat: number;
  lng: number;
  displayName: string;
}

export async function getLocation(query: string, customOptions?: getLocationOptions): Promise<Location[]> {
  try {
    const url = `${baseUrl}/location`;
    const defaultOptions = { count: 5 };
    const options = { ...defaultOptions, ...customOptions };

    const config = {
      params: {
        q: query,
        c: options.count,
        type: "Thoroughfarename",
      },
      adapter: jsonpAdapter,
    };
    const response = await axios(url, config);
    const formattedResponse = response.data.LocationResult.map((item: any): Location => {
      return {
        id: item.ID,
        lat: item.Location.Lat_WGS84,
        lng: item.Location.Lon_WGS84,
        displayName: item.FormattedAddress,
      };
    });
    return formattedResponse;
  } catch (error) {
    throw error;
  }
}
