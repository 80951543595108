import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLayers } from "./useLayers";

export function usePersistLayersInSearchParams() {
  const { layers, setLayers, activeLayers } = useLayers();
  const history = useHistory();
  const queryParams = useLocation().search;
  const params = new URLSearchParams(queryParams);

  useEffect(() => {
    const layersToActivate = params.getAll("layers");

    if (layersToActivate.length === 0) {
      // if no layers set in url, use `isTrue` attribute from config
    } else {
      // set active layers based on search params
      const updatedLayers = layers.map((layer) => {
        return {
          ...layer,
          isActive: layersToActivate.includes(layer.id.toString()),
        };
      });

      setLayers(updatedLayers);
    }

    // only on init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // persist active layers in search params
  useEffect(() => {
    params.delete("layers");
    activeLayers.forEach((layer) => {
      params.append("layers", layer.id.toString());
    });
    history.push({ search: params.toString() });

    // only when active layers change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayers]);
}
