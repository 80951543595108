import { toNumber } from "lodash-es";
import axios from "axios";

const baseUrl = " https://nominatim.openstreetmap.org";

interface getLocationOptions {
  count: number;
}

export interface Location {
  id: string;
  lat: number;
  lng: number;
  displayName: string;
}

export async function getLocation(query: string, customOptions?: getLocationOptions): Promise<Location[]> {
  try {
    const url = `${baseUrl}/search`;
    const defaultOptions: getLocationOptions = { count: 5 };
    const options = { ...defaultOptions, ...customOptions };

    const config = {
      params: {
        format: "json",
        q: query,
        limit: options.count,
      },
    };
    const response = await axios(url, config);

    const formattedResponse = response.data.map((item: any): Location => {
      return {
        id: item.osm_id,
        lat: toNumber(item.lat),
        lng: toNumber(item.lon),
        displayName: item.display_name,
      };
    });

    return formattedResponse;
  } catch (error) {
    throw error;
  }
}

interface getReverseLocationOptions {
  zoom: number;
}

interface getReverseLocationResponse {
  displayName: string;
  country: string;
  countryCode: string;
}

export async function getReverseLocation(
  lat: number,
  lng: number,
  customOptions?: getReverseLocationOptions
): Promise<getReverseLocationResponse> {
  try {
    const url = `${baseUrl}/reverse`;
    const defaultOptions: getReverseLocationOptions = {
      zoom: 18, // zoom can be 0 (Country), or 18 (house/building), note that at the time of writing zoom level 0 does not fully covers Europe
    };
    const options = { ...defaultOptions, ...customOptions };

    const config = {
      params: {
        format: "json",
        lat,
        lon: lng,
        zoom: options.zoom,
      },
    };
    const { data } = await axios(url, config);

    const formattedResponse = {
      displayName: data.display_name,
      country: data.address.country,
      countryCode: data.address.country_code,
    };
    return formattedResponse;
  } catch (error) {
    throw error;
  }
}
