import { LatLngLiteral } from "leaflet";
import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Layer } from "../types";

export type Selection = SelectionFeature | SelectionMarker;
export type SelectionFeature = {
  type: "feature";
  data: { featureId: string; layerId: Layer["id"] };
};
export type SelectionMarker = { type: "marker"; data: LatLngLiteral };

export function useMarker() {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();

  const marker = useMemo(() => {
    const rawMarker = params.get("marker");
    if (!rawMarker) return undefined;
    else {
      const [lat, lng] = rawMarker.split(",");
      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    }
  }, [params]);

  function setMarker(latlng: LatLngLiteral) {
    const { lat, lng } = latlng;
    // TODO: think of a way to not tie feature and marker together
    params.delete("feature");
    params.set("marker", [lat, lng].join(","));
    history.push({ search: params.toString() });
  }

  return { marker, setMarker };
}

export function useFeature() {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();

  const feature = useMemo(() => {
    const rawFeature = params.get("feature");
    if (!rawFeature) return undefined;
    else {
      const [featureId, layerId] = rawFeature.split(",");
      return { featureId, layerId };
    }
  }, [params]);

  function setFeature(data: SelectionFeature["data"]) {
    const { featureId, layerId } = data;
    // TODO: think of a way to not tie feature and marker together
    params.delete("marker");
    params.set("feature", [featureId, layerId].join(","));
    history.push({ search: params.toString() });
  }

  return { feature, setFeature };
}

export function useMapSelection() {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();

  const { marker, setMarker } = useMarker();
  const { feature, setFeature } = useFeature();

  function setMapSelection(selection: Selection) {
    // set new selection
    switch (selection.type) {
      case "marker":
        setMarker(selection.data);
        break;
      case "feature":
        setFeature(selection.data);
        break;
    }
  }

  function clearSelection() {
    params.delete("marker");
    params.delete("feature");
    history.push({ search: params.toString() });
  }

  const mapSelection = useMemo((): Selection | undefined => {
    if (feature) return { type: "feature", data: feature };
    if (marker) return { type: "marker", data: marker };

    return undefined;
  }, [feature, marker]);

  return { mapSelection, setMapSelection, clearSelection };
}
