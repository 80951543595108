import { Drawer } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  width?: number | string;
}

const TreeDrawer = styled(Drawer)<Props>`
  min-width: ${({ width }) => (typeof width === "string" ? width : `${width}px`)};

  .MuiPaper-root {
    min-width: ${({ width }) => (typeof width === "string" ? width : `${width}px`)};
    width: ${({ width }) => (typeof width === "string" ? width : `${width}px`)};
    top: ${({ theme }) => theme.mixins.toolbar.height}px;
  }
`;

TreeDrawer.defaultProps = { width: "45%" };
export { TreeDrawer };
