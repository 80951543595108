import { Drawer, Toolbar, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import styled from "styled-components";

export function PanelDrawerHeader(props: { children: ReactNode }) {
  return (
    <PanelDrawerHeaderStyles>
      <Typography variant="h6" color="primary">
        {props.children}
      </Typography>
    </PanelDrawerHeaderStyles>
  );
}
const PanelDrawerHeaderStyles = styled(Toolbar)`
  position: relative;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  background-color: #fafafa;
  padding: 0 24px 0 32px;
  display: flex;
  flex: 0 0 60px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px solid #e1e1e1;
  box-sizing: content-box;
  text-transform: uppercase;

  .MuiTypography-root {
    display: flex;
    flex-grow: 1;
    align-items: center;

    & > *:last-child {
      margin-left: auto;
    }
  }
`;

export const PanelDrawerBody = styled.div<{ spacing?: boolean }>`
  padding: ${({ theme }) => theme.spacing(3)}px;
  background-color: white;
`;

interface Props {
  width?: number;
}

const PanelDrawer = styled(Drawer)<Props>`
  flex: 0 1 ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: white;

  &.MuiDrawer-root {
    z-index: ${(props) => props.theme.zIndex.drawer} !important;
  }

  .MuiDrawer-paperAnchorLeft {
    left: 60px;
    border-left: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  .MuiPaper-root {
    min-width: ${(props) => props.width}px;
    width: ${(props) => props.width}px;
    top: ${({ theme }) => theme.mixins.toolbar.height}px;
  }
`;

PanelDrawer.defaultProps = { width: 300 };
export { PanelDrawer };
