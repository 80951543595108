import { TreeView } from "@material-ui/lab";
import { ExpandMore, ChevronRight } from "@material-ui/icons";

import { useLayers } from "../../hooks/useLayers";

import { Layer } from "../../types";
import { TreeViewerNode } from "./TreeViewerNode";
import { layersToTreeData, getRootNodes } from "../../utils/tree.utils";

interface Props {
  layers: Layer[];
}

export const TreeViewer = (props: Props) => {
  const { layers } = props;
  const { activeLayers, toggleLayer } = useLayers(true);

  const treeData = layersToTreeData(layers);
  const rootNodes = getRootNodes(treeData);

  return (
    <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />}>
      {rootNodes.map((node, i) => (
        <TreeViewerNode
          level={0}
          onToggle={toggleLayer}
          activeLayers={activeLayers}
          node={node}
          data={treeData.filter((branch) => branch.key.indexOf(node.key) === 0)}
          index={`${i}`}
          key={`${i}-root`}
        />
      ))}
    </TreeView>
  );
};
