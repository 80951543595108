import { useMemo } from "react";
import { Checkbox } from "@material-ui/core";
import { TreeItem } from "@material-ui/lab";
import { upperFirst } from "lodash-es";

import { Layer, TreeBranch } from "../../types";

interface Props {
  activeLayers: Layer[];
  onToggle: (toggle: Layer) => void;
  node: TreeBranch | Layer;
  data: TreeBranch[];
  index: string;
  level: number;
}

export const TreeViewerNode = (props: Props) => {
  const { node, data, index, level, activeLayers, onToggle } = props;

  function isLayer(data: TreeBranch | Layer): data is Layer {
    return data.hasOwnProperty("category");
  }
  function isBranch(data: TreeBranch | Layer): data is TreeBranch {
    return data.hasOwnProperty("key") && data.hasOwnProperty("data");
  }

  const currentLabel = useMemo(() => {
    const parts = !isLayer(node) ? node.key.split(".") : [node.title];
    if (parts.length > level) {
      return upperFirst(parts[level]);
    } else if (parts.length <= level) {
      return parts[parts.length - 1];
    }
    return "";
  }, [node, level]);

  return (
    <>
      {isLayer(node) && (
        <TreeItem
          nodeId={`${index}`}
          label={currentLabel}
          onLabelClick={() => onToggle(node)}
          icon={
            <Checkbox
              color="primary"
              checked={activeLayers.find((search) => search.id === node.id) !== undefined}
              onClick={() => onToggle(node)}
            />
          }
        />
      )}
      {isBranch(node) && (
        <TreeItem nodeId={`${index}`} label={currentLabel}>
          {data.map((branch, i) => {
            if (branch.key !== node.key) {
              return (
                <TreeViewerNode
                  activeLayers={activeLayers}
                  onToggle={onToggle}
                  level={level + 1}
                  key={`${index}${i}-branch`}
                  index={`${index}${i}`}
                  node={branch}
                  data={data.filter((chidBranch) => chidBranch.key.indexOf(branch.key) === 0)}
                />
              );
            }
            return null;
          })}
          {node.data.map((branch, i) => {
            if (isLayer(branch)) {
              return (
                <TreeItem
                  nodeId={`${index}${i}-layer`}
                  key={`${index}${i}-layer`}
                  label={branch.title}
                  onLabelClick={() => onToggle(branch)}
                  icon={
                    <Checkbox
                      color="primary"
                      checked={activeLayers.find((search) => search.id === branch.id) !== undefined}
                    />
                  }
                  onClick={() => onToggle(branch)}
                />
              );
            }
            return null;
          })}
        </TreeItem>
      )}
    </>
  );
};
