import { Suspense } from "react";

import { useConfig } from "./hooks/useConfig";
import { LayersProvider } from "./context/layers";
import { RootPage } from "./pages/RootPage";
import { PageLoader } from "./components/page/loading/PageLoader";

export const App = () => {
  const { data: config } = useConfig();

  if (!config) return null;

  return (
    <Suspense fallback={<PageLoader />}>
      <LayersProvider config={config}>
        <RootPage config={config} />
      </LayersProvider>
    </Suspense>
  );
};
