import axios from "axios";
import { Layer, MapPositionBounds } from "../types";
import { FeatureCollection, Feature } from "geojson";
import convertXmlJs from "xml-js";
var GeoJSON = require("geojson");

export async function getFeatureInfo(layer: Layer, event: MapPositionBounds): Promise<Feature | undefined> {
  const { x, y, bbox, width, height } = event;
  const config = {
    params: {
      version: "1.1.0",
      request: "GetFeatureInfo",
      service: "WMS",
      srs: "EPSG:4326",
      layers: layer.wms.layers,
      query_layers: layer.wms.layers,
      x: Math.round(x), // ensure only integers as value
      y: Math.round(y), // ensure only integers as value
      bbox,
      width: width,
      height: height,
    },
  };

  try {
    const { data } = await axios.get(layer.wms.url, config);
    const converted: any = convertXmlJs.xml2js(data, { compact: true });

    var singleobject = {
      ...converted.FeatureInfoResponse.FIELDS._attributes,
      lat: event.position.lat,
      lng: event.position.lng,
    };
    return GeoJSON.parse(singleobject, { Point: ["lat", "lng"] });
  } catch (ex) {
    console.warn(ex);
    return;
  }
}

export async function getFeature(layer: Layer, cqlValue: string): Promise<FeatureCollection | undefined> {
  if (layer.wfs === undefined) {
    console.warn("Can't perform request GetFeature without wfs config");
    return;
  }
  const config = {
    params: {
      version: "1.0.0",
      request: "GetFeature",
      service: "WFS",
      outputFormat: "application/json",
      srsName: "EPSG:4326",
      typename: layer.wfs.typename,
      CQL_FILTER: layer.wfs.cqlFilter + "=" + cqlValue,
    },
  };

  try {
    const { data } = await axios.get(layer.wfs!.url, config);
    return data;
  } catch (ex) {
    console.error(ex);
  }
}
