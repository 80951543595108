import { useState } from "react";
import { ListAlt } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import { ControlOptions } from "leaflet";
import { LegendsWindow } from "../legend/LegendsWindow";
import { MapControlBase } from "./MapControlBase";
import { useLayers } from "../../hooks/useLayers";

interface Props extends ControlOptions {}

export const LegendControl = (props: Props) => {
  const { activeLayers } = useLayers();
  const { ...controlOptions } = props;
  const [open, setOpen] = useState(false);

  // render the component
  return render();

  function render() {
    return (
      <MapControlBase {...controlOptions}>
        {open && renderOpen()}
        {!open && renderClosed()}
      </MapControlBase>
    );
  }

  function renderOpen() {
    return <LegendsWindow layers={activeLayers} onClose={() => setOpen(!open)} className="windowLegend" />;
  }

  function renderClosed() {
    return (
      <Fab onClick={() => activeLayers.length > 0 && setOpen(!open)} disabled={activeLayers.length < 1} size="small">
        <ListAlt name="list-alt" className="mapIcon" />
      </Fab>
    );
  }
};
