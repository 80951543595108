import { useMemo, useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Input, Checkbox, ListItemText, TextField } from "@material-ui/core";
import { isEmpty } from "lodash-es";
import { useLayers } from "../hooks/useLayers";
import { useDebounce } from "../hooks/useDebounce";
import { SearchSelection } from "../pages/SearchPage";

import { Layer } from "../types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  data: SearchSelection;
  onChange: (data: SearchSelection) => void;
}

export function SearchForm(props: Props) {
  const { data } = props;
  const { layers } = useLayers();

  const searchLayers = useMemo(
    () => layers?.filter((layer) => layer.attributes.some((attribute) => attribute.search)),
    [layers]
  );

  const searchLayer = useMemo(() => layers?.find((layer) => layer.id === data.layerId), [layers, data.layerId]);

  return render();

  function render() {
    return (
      <>
        <FormControl className="form-control" fullWidth>
          <InputLabel>Layer to search within</InputLabel>
          <SearchLayerSelect
            layers={searchLayers}
            data={props.data}
            onChange={(searchLayerId) => props.onChange({ ...props.data, layerId: searchLayerId })}
          />
        </FormControl>

        {searchLayer && (
          <SearchAttributesSelect
            layer={searchLayer}
            data={props.data}
            onChange={(searchAttributes) => props.onChange({ ...data, attributes: searchAttributes })}
          />
        )}

        {!isEmpty(data.attributes) && (
          <SearchQuery data={props.data} onChange={(query) => props.onChange({ ...props.data, query })} />
        )}
      </>
    );
  }
}

function SearchLayerSelect(props: {
  layers: Layer[];
  onChange: (layerId: Layer["id"]) => void;
  data: SearchSelection;
}) {
  const { data, layers } = props;
  return render();

  function render() {
    return (
      <Select
        fullWidth
        value={data.layerId || ""}
        onChange={(
          event: React.ChangeEvent<{
            value: unknown;
          }>
        ) => props.onChange(event.target.value as Layer["id"])}
      >
        {layers.map((layer) => (
          <MenuItem key={layer.id} value={layer.id}>
            {layer.title}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

function SearchAttributesSelect(props: {
  layer: Layer;
  data: SearchSelection;
  onChange: (searchAttributes: string[]) => void;
}) {
  const { layer, data } = props;

  const searchAttributes = useMemo(() => layer.attributes.filter((attribute) => attribute.search), [layer.attributes]);

  return render();

  function render() {
    return (
      <FormControl className="form-control" fullWidth>
        <InputLabel id="demo-simple-select-label">search attributes</InputLabel>
        <Select
          fullWidth
          multiple
          value={data.attributes}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            props.onChange(event.target.value as string[]);
          }}
          input={<Input />}
          renderValue={(selected) => (selected as string[]).join(", ")}
          MenuProps={{
            ...MenuProps,
            variant: "menu",
            getContentAnchorEl: null,
          }}
        >
          {searchAttributes.map((attribute) => (
            <MenuItem key={attribute.key} value={attribute.key}>
              <Checkbox checked={data.attributes.indexOf(attribute.key) > -1} />
              <ListItemText primary={attribute.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

function SearchQuery(props: { data: SearchSelection; onChange: (query: string) => void }) {
  const QUERY_LENGTH_MIN = 4;
  const DEBOUNCE_INTERVAL = 300;

  const { data, onChange } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallBack = useMemo(() => onChange, []);
  const [value, setValue] = useState(data.query);

  //  only trigger on change after DEBOUNCE_INTERVAL
  const debouncedValue = useDebounce(value, DEBOUNCE_INTERVAL);
  useEffect(() => {
    if (!debouncedValue) return;

    // only trigger change when min characters typed
    console.log("useEffect lalalal");
    if (debouncedValue.length >= QUERY_LENGTH_MIN) onChangeCallBack(debouncedValue);
  }, [debouncedValue, onChangeCallBack]);

  return (
    <TextField
      label="Search query"
      className="form-control"
      fullWidth
      value={value}
      onChange={(event: React.ChangeEvent<{ value: string }>) => setValue(event.target.value)}
      // onChange={(event: React.ChangeEvent<{ value: string }>) => props.onChange(event.target.value)}
    />
  );
}
