import { Layer } from "../types";

export function getVariant(layer: Layer) {
  const { variants, activeVariant } = layer;

  // does this layer have variants?
  if (!variants) return null;
  if (variants?.list.length === 0) return null;

  // is an active variant selected?
  if (activeVariant) {
    const variant = variants.list.find((variant) => variant.key === activeVariant);
    if (variant) return variant;
  }
  // else
  // return first variant (as default)
  return variants.list[0];
}

export function getStyle(layer: Layer) {
  const variant = getVariant(layer);

  if (variant) return variant.style;
  else return layer.wms.styles;
}
