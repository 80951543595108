import { useWFSGetFeature } from "../hooks/ogc";
import { useLayers } from "../hooks/useLayers";
import { SearchSelection } from "../pages/SearchPage";

export function useSearchResult(
  layerId: NonNullable<SearchSelection["layerId"]>,
  attributes: NonNullable<SearchSelection["attributes"]>,
  query: NonNullable<SearchSelection["query"]>
) {
  // const { layerId, attributes, query } = data;
  const { layers } = useLayers();
  const searchLayer = layers?.find((l) => layerId === l.id);

  return useWFSGetFeature<any>(
    searchLayer?.wfs?.url || "",
    {
      // @ts-ignore
      typenames: searchLayer.wms.layers,
      propertyName: [...attributes, "geom"].join(","),
      count: 20,
      cql_filter: attributes
        .map((attribute) => `strToLowerCase(${attribute}) like '%${query?.toLowerCase()}%'`)
        .join(" or "),
    },
    {
      enabled: searchLayer && query !== null,
    }
  );
}
