import { NavLink, useLocation } from "react-router-dom";

import { Info as InfoIcon, Layers as LayersIcon, Settings as SettingsIcon } from "@material-ui/icons";
import styled from "styled-components";
import { darken, List, ListItem, Tooltip } from "@material-ui/core";
import { SearchLocation } from "../icons/SearchLocation";
import { isSearchLayerEnabled } from "../../utils/config.utils";
import { IConfig } from "../../types";

interface Props {
  config: IConfig;
}

export const MainNavigation = (props: Props) => {
  const location = useLocation();

  const searchLayerEnabled = isSearchLayerEnabled(props.config);

  return (
    <StyledList>
      <Tooltip title="Layers" placement="right" arrow>
        <ListItem
          button
          component={NavLink}
          to={{ ...location, pathname: "/layers" }}
          className="navigation-item"
          activeClassName="active"
        >
          <LayersIcon className="icon" />
        </ListItem>
      </Tooltip>

      {searchLayerEnabled && (
        <Tooltip title="Search for shape in layers" placement="right" arrow>
          <ListItem
            button
            component={NavLink}
            to={{ ...location, pathname: "/search" }}
            className="navigation-item"
            activeClassName="active"
          >
            <SearchLocation className="icon" />
          </ListItem>
        </Tooltip>
      )}

      <Tooltip title="Configuration" placement="right" arrow>
        <ListItem
          button
          component={NavLink}
          to={{ ...location, pathname: "/configuration" }}
          className="navigation-item"
          activeClassName="active"
        >
          <SettingsIcon className="icon" />
        </ListItem>
      </Tooltip>
      <Tooltip title="About the application" placement="right" arrow>
        <ListItem
          button
          component={NavLink}
          to={{ ...location, pathname: "/info" }}
          className="navigation-item"
          activeClassName="active"
        >
          <InfoIcon className="icon" />
        </ListItem>
      </Tooltip>
    </StyledList>
  );
};

const StyledList = styled(List)`
  width: ${({ theme }) => theme.navigationDrawer.width}px;
  padding: 0;

  .navigation-item {
    width: ${({ theme }) => theme.navigationDrawer.width}px;
    height: ${({ theme }) => theme.navigationDrawer.width}px;

    &:hover {
      background-color: ${({ theme }) => theme.palette.grey[200]};
    }

    .icon {
      color: #737373;
    }

    &.active {
      background-color: ${({ theme }) => theme.palette.primary.light};
      border-right: 2px solid #212121;

      &:hover {
        background-color: ${({ theme }) => darken(theme.palette.primary.light, 0.1)};
      }
      .icon {
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
  }
`;
