import { useState } from "react";
import { ScaleControl, TileLayer, MapContainer, WMSTileLayer } from "react-leaflet";
import { IConfig, Mask } from "../../types";
import styled from "styled-components";
import { PersistMapPosition } from "./PersistMapPosition";
import { MapSizeObserver } from "./MapSizeObserver";
import { useMapPosition } from "../../hooks/useMapPosition";
import { MapClickHandler } from "./MapClickHandler";
import { LocationControl } from "./LocationControl";
import { LayerPropertiesControl } from "./LayerPropertiesControl";
import { LegendControl } from "./LegendControl";
import { OverlayerLayer } from "./OverlayerLayer";
import { useConfig } from "../../hooks/useConfig";
import { useLayers } from "../../hooks/useLayers";
import { useBaseLayer } from "../../hooks/useBaseLayer";
import { MapZoomControls } from "./MapZoomControls";
import { SelectionOnMap } from "./SelectionOnMap";

interface Props {
  config: IConfig;
}

export const MapContent = (props: Props) => {
  const { config } = props;
  const { baselayer } = useBaseLayer(config);
  const position = useMapPosition(config.initialPosition);

  const { activeLayers } = useLayers();
  const { data: { masks } = { masks: Array<Mask>() } } = useConfig();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  // render the component
  return render();

  function render() {
    if (!activeLayers) return null;
    if (!masks) return null;

    return (
      <Styles className={isDrawerOpen ? "drawer-open" : "drawer-closed"}>
        <MapContainer
          center={[position.lat, position.lng]}
          zoom={position.zoom}
          zoomControl={false}
          minZoom={0}
          maxBoundsViscosity={1}
          attributionControl={true}
        >
          <LocationControl position="topright" />
          <LayerPropertiesControl open={isDrawerOpen} onChangeOpen={setIsDrawerOpen} />
          <ScaleControl position="bottomleft" metric={true} imperial={false} updateWhenIdle={true} />
          <LegendControl position="bottomleft" />
          <MapZoomControls position="bottomright" />
          <PersistMapPosition />
          <MapSizeObserver />
          <MapClickHandler />

          {baselayer && (
            <TileLayer key={baselayer.key} attribution={baselayer.attribution} url={baselayer.url} zIndex={-1} />
          )}

          {activeLayers.map((overLayer, i) => (
            <OverlayerLayer layer={overLayer} zIndex={activeLayers.length - i} key={`${overLayer.wms.layers}-${i}`} />
          ))}

          {masks.map((mask, i) => (
            <WMSTileLayer
              key={`mask-${mask.layers}-${i}`}
              url={mask.url}
              layers={mask.layers}
              styles={mask.styles}
              format={mask.format}
              transparent={mask.transparent}
              opacity={mask.opacity}
              zIndex={activeLayers.length + ++i}
              // @ts-ignore
              tiled
            />
          ))}
          <SelectionOnMap />
        </MapContainer>
      </Styles>
    );
  }
};

const Styles = styled.div`
  flex-grow: 1;
  z-index: 1;
  display: flex;

  .leaflet-container {
    flex-grow: 1;
    z-index: 1;
    margin-right: 40px;
    transition: 320ms margin-right ease-out;
  }

  &.drawer-open .leaflet-container {
    margin-right: 320px;
  }

  .leaflet-control-scale {
    margin: 0;
  }
`;
