import { List, ListItem, Slider, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { getVariant } from "../../utils/layer.utils";
import { Layer, LayerVariant } from "../../types";
import { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  layer: Layer;
  onChangeVariant: (layer: Layer, variant: LayerVariant) => void;
  onChangeOpacity: (opacity: number) => void;
}

export function LayerItemDetails(props: Props) {
  const { layer } = props;

  /**
   * Functions
   */
  function handleVariantChange(event: ChangeEvent<{ name?: string; value: unknown }>) {
    const { value } = event.target;
    const result = (layer.variants?.list || []).find((variant) => variant.key === value);
    if (result) props.onChangeVariant(layer, result);
  }

  function handleOpacityChange(_: ChangeEvent<{}>, value: number | number[]) {
    if (!Array.isArray(value)) props.onChangeOpacity(value / 100);
  }

  // render the component
  return render();

  function render() {
    return (
      <List disablePadding>
        <ListItem component={VerticalListItem} selected>
          {layer.variants && (
            <FormControl variant="outlined" className="layerInfoItem" fullWidth>
              <InputLabel>{layer.variants.label || ""}</InputLabel>
              <Select
                label={layer.variants.label || ""}
                value={getVariant(layer)?.key}
                labelId="styles-selector-label"
                id="styles-selector"
                onChange={handleVariantChange}
              >
                {layer.variants.list.map((variant) => (
                  <MenuItem value={variant.key} key={variant.key}>
                    {variant.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className="layerInfoItem">
            <div className="label">Transparency</div>
            <Slider min={0} max={100} step={10} value={layer.opacity * 100} onChange={handleOpacityChange} />
          </div>

          {layer.description && (
            <div className="layerInfoItem">
              <div className="label">About this layer</div>
              <div className="value">{layer.description}</div>
            </div>
          )}
          <div className="layerInfoItem">
            <div className="label">Source</div>
            <div className="value">{layer.source}</div>
          </div>

          {/* TODO: sourceAttribution to be deprecated in favor of information list */}
          {layer.sourceAttribution && (
            <div className="layerInfoItem">
              <div className="label">Source attribution</div>
              <div className="value">{layer.sourceAttribution}</div>
            </div>
          )}

          {layer.information &&
            layer.information.map((informationItem, i) => (
              <div key={i} className="layerInfoItem">
                <div className="label">{informationItem.title}</div>
                <div className="value">{informationItem.body}</div>
              </div>
            ))}
        </ListItem>
      </List>
    );
  }
}

const VerticalListItem = styled(ListItem)`
  flex-direction: column;
  align-items: unset;

  padding: ${({ theme }) => theme.spacing(2, 4)};

  .layerInfoItem {
    & + .layerInfoItem {
      margin-top: 15px;
    }

    .label {
      font-family: "AvenirLT-BookOblique", Helvetica, Arial;
      opacity: 0.5;
      padding-bottom: 5px;
    }

    .value {
      font-family: "AvenirLT-Book", Helvetica, Arial;
      line-height: 1.4em;
    }
  }
`;
