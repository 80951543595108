import Axios from "axios";
import { useQuery } from "react-query";

async function fetch(url: string) {
  const response = await Axios.get<string>(url);
  if (response.status === 200) return response.data;
}

export const useAbout = (url: string | undefined) => {
  console.log("🌀 ~ file: aboutHooks.ts ~ line 10 ~ url", url);
  return useQuery("about-md", () => fetch(url || ""), {
    enabled: !!url,
    staleTime: 7200000,
  });
};
