import * as ogcService from "../services/ogc.service";
import * as infoVLService from "../services/informatie-vlaanderen.service";
import { Layer, MapPositionBounds, WMSFeatureInfoParams } from "../types";
import { FeatureCollection, Feature } from "geojson";
import { useQuery } from "react-query";
import { useMap } from "react-leaflet";
import { LatLngExpression } from "leaflet";

async function getFeature(layer: Layer, event: MapPositionBounds): Promise<FeatureCollection | Feature | undefined> {
  switch (layer.sourceType) {
    case "INFORMATIE_VLAANDEREN":
      return getFeatureInfoVlaanderen(layer, event);

    case "DEFAULT":
      return ogcService.getWMSFeatureInfo(layer, event);
  }
}

async function getFeatureInfoVlaanderen(
  layer: Layer,
  event: MapPositionBounds
): Promise<FeatureCollection | Feature | undefined> {
  const { features } = layer;

  if (features.includes("getAttributes") || features.includes("getGeometry")) {
    const featureInfo = await infoVLService.getFeatureInfo(layer, event);

    if (featureInfo === undefined) {
      return;
    }

    // if no interest in getGeometry info, return Feature with only Point
    if (!features.includes("getGeometry")) {
      return featureInfo;
    }

    const filterValue = featureInfo.properties![layer.wfs?.cqlFilter || ""];

    return infoVLService.getFeature(layer, filterValue);
  }
}

export const useFeature = (layer: Layer, mapPosition: MapPositionBounds) => {
  return useQuery(["feature", layer.id, mapPosition.position], () => getFeature(layer, mapPosition), {
    retry: false,
    enabled: layer.attributes.length > 0 || ["", undefined].indexOf(layer.variants?.uniqueProperty) === -1,
  });
};

export const useMapPositionBounds = (
  latlng: LatLngExpression
): Pick<WMSFeatureInfoParams, "x" | "y" | "width" | "height" | "bbox"> => {
  const map = useMap();
  const containerPoint = map.latLngToContainerPoint(latlng);
  const size = map.getSize();
  const bbox = map.getBounds().toBBoxString();

  return { width: size.x, height: size.y, bbox, ...containerPoint };
};
