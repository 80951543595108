import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Typography,
} from "@material-ui/core";
import { PanelDrawer, PanelDrawerBody, PanelDrawerHeader } from "../components/drawer/PanelDrawer";
import { useBaseLayer } from "../hooks/useBaseLayer";
import { IConfig } from "../types";

interface Props {
  config: IConfig;
}

export const ConfigurationPage = (props: Props) => {
  const { config } = props;
  const { baselayer: activeBaseLayer, setBaselayer } = useBaseLayer(config);

  return render();

  function render() {
    return (
      <PanelDrawer variant="permanent">
        <PanelDrawerHeader>Configuration</PanelDrawerHeader>
        <PanelDrawerBody>
          <Typography variant="h4">Background layer</Typography>
        </PanelDrawerBody>
        <List>
          {config.baseLayers.map((baseLayer) => (
            <ListItem
              button
              key={baseLayer.key}
              onClick={() => setBaselayer(baseLayer)}
              selected={baseLayer.key === activeBaseLayer?.key}
            >
              <ListItemAvatar>
                <Avatar alt={`Preview image of Base layer ${baseLayer.title}`} src={baseLayer.image} />
              </ListItemAvatar>
              <ListItemText primary={baseLayer.title} secondary={baseLayer.description} />
              <ListItemSecondaryAction>
                <Radio
                  edge="end"
                  color="primary"
                  checked={baseLayer.key === activeBaseLayer?.key}
                  onClick={() => setBaselayer(baseLayer)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </PanelDrawer>
    );
  }
};
