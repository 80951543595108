import { StyledMarkdown } from "../components/markdown/StyledMarkdown";
import { PanelDrawer, PanelDrawerBody, PanelDrawerHeader } from "../components/drawer/PanelDrawer";
import { useAbout } from "../hooks/aboutHooks";
import { IConfig } from "../types";

interface Props {
  config: IConfig;
}

export const AboutPage = (props: Props) => {
  const { config } = props;
  const { data } = useAbout(config.about);

  return render();

  function render() {
    if (!data) return null;

    return (
      <PanelDrawer variant="permanent">
        <PanelDrawerHeader>About the tool</PanelDrawerHeader>
        <PanelDrawerBody>
          <StyledMarkdown source={data} />
        </PanelDrawerBody>
      </PanelDrawer>
    );
  }
};
