import Light from "../assets/fonts/354D4F_7_0.woff";
import LightOblique from "../assets/fonts/354D4F_9_0.woff";
import Medium from "../assets/fonts/354D4F_0_0.woff";
import Roman from "../assets/fonts/354D4F_1_0.woff";
import Book from "../assets/fonts/354D4F_6_0.woff";
import Black from "../assets/fonts/354D4F_4_0.woff";

const MediumFont = {
  fontFamily: "AvenirLT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
  local('AvenirLT-Medium'),
  local('AvenirLT-Medium'),
  url(${Medium}) format('woff')
`,
};
const RomanFont = {
  fontFamily: "AvenirLT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
  local('AvenirLT-Roman'),
  local('AvenirLT-Roman'),
  url(${Roman}) format('woff')
`,
};
const BookFont = {
  fontFamily: "AvenirLT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
  local('AvenirLT-Book'),
  local('AvenirLT-Book'),
  url(${Book}) format('woff')
`,
};
const BlackFont = {
  fontFamily: "AvenirLT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
  local('AvenirLT-Black'),
  local('AvenirLT-Black'),
  url(${Black}) format('woff')
`,
};
const LightFont = {
  fontFamily: "AvenirLT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
  local('AvenirLT-Light'),
  local('AvenirLT-Light'),
  url(${Light}) format('woff')
`,
};
const LightObliqueFont = {
  fontFamily: "AvenirLT",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
  local('AvenirLT-LightOblique'),
  local('AvenirLT-LightOblique'),
  url(${LightOblique}) format('woff')
`,
};

export const fontFaces = [BlackFont, MediumFont, BookFont, RomanFont, LightFont, LightObliqueFont];
