import { Layer } from "../../types";
import { Window } from "../window/Window";
import { Legend } from "./Legend";
import styled from "styled-components";

interface Props {
  layers: Layer[];
  onClose: () => void;
  className?: string;
}

export const LegendsWindow = (props: Props) => {
  const { layers, className, onClose } = props;

  return (
    <Window title="Legend" onClose={onClose} className={className}>
      <LegendStyle className="windowLegends">
        {layers.map((layer) => (
          <div key={layer.id} className="legendGroup">
            <div className="legend-title">{layer.title}</div>
            <Legend layer={layer} />
          </div>
        ))}
      </LegendStyle>
    </Window>
  );
};

const LegendStyle = styled.div`
  .legendGroup {
    & + .legendGroup {
      margin-top: 20px;
    }
  }

  .legend-title {
    font-size: 13px;
    font-weight: 400;
  }
`;
