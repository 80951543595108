import { Drawer } from "@material-ui/core";
import styled from "styled-components";

import { MainNavigation } from "../components/navigation/MainNavigation";
import { IConfig } from "../types";

interface Props {
  config: IConfig;
  children: NonNullable<React.ReactNode>;
  noAuthentication: boolean;
}
export function AppLayout(props: Props) {
  return (
    <Styles>
      <Drawer className="drawer" variant="permanent" anchor="left">
        <MainNavigation config={props.config} />
      </Drawer>

      <main className="content">{props.children}</main>
    </Styles>
  );
}
AppLayout.defaultProps = { noAuthentication: false };

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;

    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    color: #f7f7f7;

    > img {
      height: 100%;
      margin-right: 10px;
    }
  }

  .content {
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    width: calc(100% - ${({ theme }) => theme.navigationDrawer.width}px);
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: ${({ theme }) => theme.navigationDrawer.width}px;
  }
`;
