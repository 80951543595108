import { WMSTileLayer, GeoJSON } from "react-leaflet";
import { useSelectionFeatureData } from "../../hooks";
import { useFeature, useMapPositionBounds } from "../../hooks/mapHooks";
import { useLayers } from "../../hooks/useLayers";
import { useMapSelection, SelectionMarker, SelectionFeature } from "../../hooks/useMarker";
import { Layer } from "../../types";
import { getStyle } from "../../utils/layer.utils";

interface Props {
  layer: Layer;
  zIndex: number;
}

export function OverlayerLayer(props: Props) {
  const { layer, zIndex } = props;
  const { mapSelection } = useMapSelection();

  return render();

  function render() {
    return (
      <>
        <WMSTileLayer
          key={layer.wms.layers + layer.activeVariant}
          url={layer.gwc !== undefined ? layer.gwc.url : layer.wms.url}
          layers={layer.wms.layers}
          styles={getStyle(layer)}
          transparent
          format="image/png"
          opacity={layer.opacity}
          minZoom={layer.wms.minZoom}
          maxZoom={layer.wms.maxZoom}
          zIndex={zIndex}
          // @ts-ignore
          tiled
        />
        {mapSelection?.type === "marker" && <SelectionMarkerType layer={layer} data={mapSelection.data} />}

        {mapSelection?.type === "feature" && <SelectionFeatureType data={mapSelection.data} />}
      </>
    );
  }
}

function SelectionMarkerType(props: { data: SelectionMarker["data"]; layer: Layer }) {
  const { layer, data } = props;
  const bounds = useMapPositionBounds(data || { lat: 0, lng: 0 });
  const { data: feature } = useFeature(layer, {
    ...bounds,
    position: data || { lat: 0, lng: 0 },
  });

  return render();
  function render() {
    if (!feature) return null;

    return (
      <GeoJSON
        data={feature}
        style={layer.geometryStyle}
        //@ts-ignore: function cannot return NULL.. for legacy purposes i'll leave it.
        //TODO: figure out how to fix this, Leaflet update? ( But that might break a wholatta other stuff )
        pointToLayer={() => {
          // render no point base GeoJSON, because multiple points would overlap
          // points are rendered by this.props.markers data
          return null;
        }}
      />
    );
  }
}

function SelectionFeatureType(props: { data: SelectionFeature["data"] }) {
  const { data } = props;
  const { layers } = useLayers();
  const layer = layers.find((layer) => layer.id === data.layerId);
  const { data: feature } = useSelectionFeatureData(data);

  return render();
  function render() {
    if (!feature) return null;
    if (!layer) return null;

    return (
      <GeoJSON
        key={data.featureId}
        data={feature}
        style={layer.geometryStyle}
        //@ts-ignore: function cannot return NULL.. for legacy purposes i'll leave it.
        //TODO: figure out how to fix this, Leaflet update? ( But that might break a wholatta other stuff )
        pointToLayer={() => {
          // render no point base GeoJSON, because multiple points would overlap
          // points are rendered by this.props.markers data
          return null;
        }}
      />
    );
  }
}
