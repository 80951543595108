import { MouseEvent, ReactNode } from "react";
import clsx from "clsx";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import { Box, IconButton, Paper, Toolbar, Typography } from "@material-ui/core";
import { useMap } from "react-leaflet";

interface Props {
  className?: string;
  title: string;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
}

export const Window = (props: Props) => {
  const { title, children, className, onClose } = props;
  const cxContainer = clsx(className, "window");
  const map = useMap();

  function toggleMapZoom(value: "enabled" | "disabled"): void {
    if (map) {
      switch (value) {
        case "enabled":
          map.scrollWheelZoom.enable();
          break;
        case "disabled":
          map.scrollWheelZoom.disable();
          break;
      }
    }
  }

  return (
    <StyledPaper
      elevation={3}
      className={cxContainer}
      onMouseOver={() => toggleMapZoom("disabled")}
      onMouseOut={() => toggleMapZoom("enabled")}
    >
      <Toolbar variant="dense" className="header">
        <Typography className="title">{title}</Typography>
        <IconButton className="close-button" edge="end" onClick={onClose}>
          <Close className="window-icon" />
        </IconButton>
      </Toolbar>
      <Box padding={[1, 2]} overflow="auto" className="body">
        {children}
      </Box>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  &.window {
    max-width: 500px;

    .header {
      background-color: ${({ theme }) => `${theme.palette.grey[500]}`};
      text-transform: uppercase;
    }

    .title {
      color: #ffffff;
      transform: translateY(2px); /** TODO: center text plix.. line-height doens't match? Figure out. */
    }
    .body {
      max-height: 77vh;
    }
    .close-button {
      margin-left: auto;
      color: #ffffff;
    }
  }
`;
