import { sortBy } from "lodash-es";

import { Layer, TreeBranch } from "../types";

export const layersToTreeData = (layers: Layer[]) => {
  //First we get all categories
  const categories = layers.reduce((data: string[], current: Layer) => {
    if (data.indexOf(current.category) === -1) return [...data, current.category];
    return [...data];
  }, []);
  //Traverse all categories and get all their nodes
  const treeData = categories.reduce((branches: TreeBranch[], category: string) => {
    const branchData = layers.filter((layer) => layer.category === category);

    return [...branches, { key: category, data: branchData }];
  }, []);

  return sortBy(treeData, ["key"]);
};

export const getRootNodes = (branches: TreeBranch[]) => {
  return branches.filter((branch) => branch.key.indexOf(".") === -1);
};
