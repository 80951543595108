import { Fab } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useState } from "react";
import { useConfig } from "../../hooks/useConfig";
import { LocationSearchBox, Location as SearchLocation } from "./LocationSearchBox";
import { MapControlBase } from "./MapControlBase";
import { ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import styled from "styled-components";

interface Props extends ControlOptions {}

export const LocationControl = (props: Props) => {
  const { ...controlOptions } = props;
  const [open, setOpen] = useState(false);
  const { data: config } = useConfig();
  const toggle = () => setOpen(!open);
  const map = useMap();

  const searchType = config ? config.searchType : "nominatim";
  function handleSearchLocationClick(event: SearchLocation) {
    const { lat, lng } = event;
    map.setView([lat, lng], 17);
  }

  return (
    <MapControlBase {...controlOptions}>
      {open && (
        <LocationSearchBox onSearchResultClick={handleSearchLocationClick} onClickOutside={toggle} type={searchType} />
      )}
      {!open && (
        <Fab component={StyledFab} onClick={toggle} size="small">
          <SearchIcon name="search" className="mapIcon" />
        </Fab>
      )}
    </MapControlBase>
  );
};
//Added extra spacing, so your icon doens't jump visually on open
const StyledFab = styled(Fab)`
  margin: ${({ theme }) => theme.spacing(1, 1.5)};
`;
