import axios from "axios";
import { Layer, MapPositionBounds } from "../types";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { parseToCollection, parseXML } from "../utils/xml.utils";

export async function getWMSFeatureInfo(
  layer: Layer,
  mapPosition: MapPositionBounds
): Promise<FeatureCollection | undefined> {
  const { x, y, bbox, width, height } = mapPosition;
  const config = {
    params: {
      version: "1.1.0",
      request: "GetFeatureInfo",
      service: "WMS",
      srs: "EPSG:4326",
      layers: layer.variants
        ? new Array(layer.variants.list.length).fill(layer.wms.layers).join(",")
        : layer.wms.layers,
      query_layers: layer.wms.layers,
      styles: (layer.variants?.list || []).map((variant) => variant.style).join(",") || layer.wms.styles,
      x: Math.round(x), // ensure only integers as value
      y: Math.round(y), // ensure only integers as value
      bbox,
      width: width,
      height: height,
      info_format: "application/json",
    },
  };

  const response = await axios.get<FeatureCollection | string>(layer.wms.url, config);
  const { data } = response;

  // TODO: refactor data parsing from data fetch
  if (
    typeof data === "string" &&
    response.headers &&
    "content-type" in response.headers &&
    response.headers["content-type"] === "text/xml"
  ) {
    const xml = parseXML(response.data as string);
    if (xml) return parseToCollection(xml, x, y);
  }

  if (typeof data === "string") {
    throw new Error("invalid data");
  }

  return data;
}

export interface WFSGetFeatureParams {
  typeNames?: string;
  cql_filter?: string;
  viewParams?: string;
  count?: number;
  request?: string;
  service?: string;
  version?: string;
  outputFormat?: string;
  srsName?: string;
}

export async function getWFSGetFeature<P>(
  url: string,
  customParams: WFSGetFeatureParams
): Promise<FeatureCollection<Geometry, P & GeoJsonProperties>> {
  const defaultParams: Partial<WFSGetFeatureParams> = {
    count: 100,
    request: "GetFeature",
    service: "WFS",
    version: "2.0",
    outputFormat: "application/json",
    srsName: "EPSG:4326",
  };
  const config = {
    params: { ...defaultParams, ...customParams },
  };

  try {
    const { data } = await axios.get(url, config);
    return data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}

export function getLegendGraphic(layer: Layer): string {
  const params = new URLSearchParams();
  params.set("version", "1.1.0");
  params.set("request", "GetLegendGraphic");
  params.set("layer", layer.wms.layers);
  params.set("format", "image/png");

  const variant = layer.variants?.list.find((variant) => variant.key === layer.activeVariant);

  if (variant) {
    // an active variant is known
    params.set("style", variant.style);
  } else if (layer.variants && layer.variants?.list.length > 0) {
    // no active variant is known, assume the fist is active
    params.set("style", layer.variants.list[0].style);
  } else {
    // no variant, use layer style
    params.set("style", layer.wms.styles);
  }

  return `${layer.wms.url}?${params.toString()}`;
}
