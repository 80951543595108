import { FeatureCollection, Feature } from "geojson";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

import { Layer, LayerVariant } from "../../types";
import { useVariantProperties } from "../../hooks/propertyHooks";
import { formatProperty, getProperties } from "../../utils/properties.utils";
import { LayerProperty } from "./LayerProperty";

export function LayerProperties(props: { layer: Layer; feature: Feature | FeatureCollection | undefined }) {
  const { feature, layer } = props;
  const properties = getProperties(feature);
  const { attributes, variants } = layer;
  const variantAttributes = variants?.attributes;
  const { data: variantProperties } = useVariantProperties(
    layer,
    properties ? properties[layer.variants?.uniqueProperty || "id"] : undefined
  );

  function labelFromStyle(labelKey: string, variants: LayerVariant[]) {
    const variant = variants.find((variant) => variant.key === labelKey);
    if (variant) return variant.label;
    return labelKey;
  }

  return render();

  function render() {
    return (
      <Styles>
        <Typography variant="h4" component="h2" gutterBottom>
          {layer.title}
        </Typography>

        <div>
          {properties &&
            attributes.map((attribute) => (
              <LayerProperty
                key={attribute.key}
                className="layer-property"
                label={attribute.label}
                value={formatProperty(properties[attribute.key], attribute)}
              />
            ))}

          {variantProperties &&
            (variantAttributes || []).map((variant) => {
              return variantProperties.map((property, i) =>
                property ? (
                  <LayerProperty
                    key={`variant-property-${i}`}
                    className="layer-property"
                    label={labelFromStyle(property[variant.labelKey], layer.variants?.list || [])}
                    value={property[variant.key]}
                  />
                ) : null
              );
            })}

          {(!properties || (!attributes.length && !variantProperties)) && <li>No properties for this point.</li>}
        </div>
      </Styles>
    );
  }
}

const Styles = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 3)};

  .layer-property:not(:first-child) {
    padding-top: ${({ theme }) => theme.spacing(1)}px;
  }
`;
