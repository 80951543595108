import { Theme } from "@material-ui/core";
import { createGlobalStyle } from "styled-components";

interface Props {
  theme: Theme;
}

export const GlobalStyle = createGlobalStyle<Props>`
  body,
  html {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  #root {
    height: 100%;
  }

  ::selection {
    color: #fff;
    background:  ${({ theme }) => `${theme.palette.primary.main}`};
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color:  ${({ theme }) => `${theme.palette.grey[100]}`};
  }
  ::-webkit-scrollbar-track {
    background-color:  ${({ theme }) => `${theme.palette.grey[200]}`};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => `${theme.palette.grey[500]}`};
    &:hover{
      background-color: ${({ theme }) => `${theme.palette.grey[700]}`};
    }
  }

  body,html {
    scrollbar-width: 'thin';
    scrollbar-color: ${({ theme }) => `${theme.palette.grey[700]} ${theme.palette.grey[100]}`};
    overflow: hidden;
  }
  #root{
    min-height: 100vh;
    display: flex;
  }
  svg {
    fill: currentColor;
  }
  strong,b{
    font-weight: 600;
  }
  .leaflet-div-icon {
    border: none;
    background-color:transparent;
  }

  strong.MuiTypography-root, .MuiTypography-root.strong {
    font-weight:500;
  }

  .mapControlBtn + .mapControl,
  .mapControlBtn + .mapControlBtn,
  .mapControl + .mapControl {
    margin-top: 15px;
  }
  .mapControl.mapControlLocationSearch {
    z-index: 99998;
  }
`;
