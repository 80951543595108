import ReactMarkdown from "react-markdown";
import styled from "styled-components";

interface Props {
  source: string | undefined;
}

export const StyledMarkdown = (props: Props) => {
  return <Styles source={props.source} linkTarget="_blank" />;
};

const Styles = styled(ReactMarkdown)`
  h1 {
    color: #212121;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    text-transform: uppercase;
  }

  h2 {
    color: #212121;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
  }

  p {
    font-size: 13px;
    line-height: 2em;
    color: #242a38;
    font-weight: 300;
  }

  ol {
    padding: 0;
    list-style-position: inside;
  }

  ul {
    padding-left: 0;
    list-style-position: inside;
    list-style-type: square;
  }

  a {
    color: #ea4084;
    font-weight: normal;
    text-decoration: underline;

    &.active {
      font-weight: bold;
    }

    &:hover {
      text-decoration: none;
    }
  }
`;
