import { LayerProperties } from "../layer/LayerProperties";
import { useLayers } from "../../hooks/useLayers";
import { SelectionFeature } from "../../hooks/useMarker";
import { useSelectionFeatureData } from "../../hooks";

interface Props {
  selection: SelectionFeature["data"];
}

export function LayerPropertiesForFeature(props: Props) {
  const { selection } = props;
  const { layers } = useLayers();
  const layer = layers.find((layer) => layer.id === selection.layerId);
  const { data: featureData } = useSelectionFeatureData(selection);

  return render();

  function render() {
    if (!layer) return null;
    if (!featureData) return null;

    return <LayerProperties layer={layer} feature={featureData} />;
  }
}
