import { useLocation } from "react-router-dom";
import { MapPosition } from "../types";

const initialPosition: MapPosition = {
  lat: 51.218157751766725,
  lng: 5.079049604319153,
  zoom: 18,
};

export function useMapPosition(initial = initialPosition) {
  const queryParams = useLocation().search;
  const params = new URLSearchParams(queryParams);

  return {
    ...initial,
    lat: parseFloat(params.get("lat") || initial.lat.toString()),
    lng: parseFloat(params.get("lon") || initial.lng.toString()),
    zoom: parseFloat(params.get("zoom") || initial.zoom.toString()),
  };
}
