import { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { QueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router";

import { RootErrorFallback } from "../components/error-boundaries/RootErrorBoundary";
import { PageLoader } from "../components/page/loading/PageLoader";
import { AppLayout } from "../layouts/AppLayout";
import { MapContent } from "../components/map/MapContent";
import { LayersPage } from "./LayersPage";
import { IConfig } from "../types";
import { useTitle } from "../hooks/useTitle";
import { ConfigurationPage } from "./ConfigurationPage";
import { AboutPage } from "./AboutPage";
import { usePersistLayersInSearchParams } from "../hooks/usePersistLayersInSearchParams";
import { SearchPage } from "./SearchPage";

interface Props {
  config: IConfig;
}

export function RootPage(props: Props) {
  const {
    config: { projectName, isTreeManaged },
  } = props;

  useTitle(`${projectName} | Map base`);

  const location = useLocation();
  usePersistLayersInSearchParams();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary FallbackComponent={RootErrorFallback} onReset={reset}>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <AppLayout config={props.config}>
                <Switch>
                  <Route path="/layers">
                    <LayersPage isTreeManaged={isTreeManaged} />
                  </Route>

                  <Route path="/search">
                    <SearchPage />
                  </Route>

                  <Route path="/configuration">
                    <ConfigurationPage config={props.config} />
                  </Route>

                  <Route path="/info">
                    <AboutPage config={props.config} />
                  </Route>

                  <Redirect to={{ ...location, pathname: "/layers" }} />
                </Switch>

                <MapContent config={props.config} />
              </AppLayout>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
