import { useHistory, useLocation } from "react-router-dom";
import { BaseLayer, IConfig } from "../types";

const initialData: BaseLayer["key"] | undefined = undefined;

export function useBaseLayer(config: IConfig, initial = initialData) {
  const history = useHistory();
  const queryParams = useLocation().search;
  const params = new URLSearchParams(queryParams);

  function getBaselayer() {
    const baselayerFromParams = config.baseLayers.find((layer) => layer.key === params.get("baselayer"));
    const baselayerFromConfig = config.baseLayers.find((baseLayer) => baseLayer.isActive);

    if (baselayerFromParams) return baselayerFromParams;
    else if (baselayerFromConfig) return baselayerFromConfig;
    else console.warn("No baselayers configured");
  }

  function setBaselayer(baselayer: BaseLayer) {
    params.set("baselayer", baselayer.key);
    history.push({ search: params.toString() });
  }

  return {
    baselayer: getBaselayer(),
    setBaselayer,
  };
}
