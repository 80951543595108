import styled from "styled-components";

export const MarkerIcon = () => {
  return (
    <MarkerStyles>
      <svg
        name="map-pin"
        fill="#33a3dc"
        className="pinIcon"
        viewBox="0 0 288 512"
        style={{ width: "26px", height: "26px" }}
      >
        <path d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" />
      </svg>
      <svg
        name="map-pin-shadow"
        fill="#33a3dc"
        className="pinShadowIcon"
        viewBox="0 0 288 512"
        style={{ width: "26px", height: "26px" }}
      >
        <path d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" />
      </svg>
    </MarkerStyles>
  );
};

const MarkerStyles = styled.div`
  border: 0;
  background-color: transparent;

  svg {
    color: #33a3dc;
  }

  .pinIcon {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .pinShadowIcon {
    position: absolute;
    bottom: 50%;
    left: 50%;
    fill: rgba(212, 212, 212, 0.2) !important;
    transform: skewX(-29deg) skewY(12deg) translateX(-10%);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 5px;
    border-radius: 100%;
    background-color: lightgrey;
  }
`;
