import { LeafletMouseEvent } from "leaflet";
import { useMapEvents } from "react-leaflet";
import { useMapSelection } from "../../hooks/useMarker";

interface Props {}

export const MapClickHandler = (props: Props) => {
  const { setMapSelection } = useMapSelection();

  useMapEvents({
    click: (event: LeafletMouseEvent) => {
      console.log(event.latlng.toBounds(1).toBBoxString());

      setMapSelection({ type: "marker", data: event.latlng });
    },
  });

  return null;
};
