import { FeatureCollection, Feature, GeoJsonProperties } from "geojson";
import { inRange } from "lodash-es";

import { LayerAttribute } from "../types";
import { userLocale } from "./locale.utils";

export function getProperties(data: Feature | FeatureCollection | undefined): GeoJsonProperties | undefined {
  if (data === undefined) return;

  if (data.type === "FeatureCollection") {
    if (data.features.length === 0) return;
    return (data as FeatureCollection).features.reduce((acc: any, feature) => {
      return { ...acc, ...feature.properties };
    }, {});
  }

  if (data.type === "Feature") {
    return (data as Feature).properties;
  }
}

export function formatProperty(value: any, attribute: LayerAttribute) {
  const { noDataValue } = attribute;

  switch (attribute.formatting) {
    case "number":
      if (
        noDataValue &&
        (noDataValue.value === value || inRange(value, noDataValue.min || -Infinity, noDataValue.max))
      ) {
        return noDataValue.label;
      }
      return formatPropertyNumber(value, attribute.formattingOptions);
    default:
      return value;
  }
}

function formatPropertyNumber(value: number, options?: Intl.NumberFormatOptions): string {
  const overridden = options ? options.localeMatcher : undefined;
  return new Intl.NumberFormat(userLocale(overridden), options).format(value);
}

export function isArray(value: string | number) {
  const r = new RegExp(/\[.+\]/, "i");
  return r.test(`${value}`);
}

export function fromArray(value: string) {
  const result = value.match(/\[(.+)\]/);
  if (result && result[1]) return result[1].split(",");
  return [];
}
