import { Layer } from "../../types";
import { useFeature, useMapPositionBounds } from "../../hooks/mapHooks";
import { SelectionMarker, useMarker } from "../../hooks/useMarker";
import { LayerProperties } from "./LayerProperties";

interface Props {
  layer: Layer;
  selection: SelectionMarker["data"];
}

export const LayerPropertiesForMarker = (props: Props) => {
  const { layer, selection } = props;

  const { marker } = useMarker();
  const bounds = useMapPositionBounds(selection || { lat: 0, lng: 0 });
  const { data: feature } = useFeature(layer, {
    ...bounds,
    position: marker || { lat: 0, lng: 0 },
  });

  return render();

  function render() {
    if (!marker) return null;
    if (!feature) return null;

    return <LayerProperties layer={layer} feature={feature} />;
  }
};
