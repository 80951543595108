import Axios from "axios";
import { FeatureCollection } from "geojson";
import { useQuery } from "react-query";
import { Layer } from "../types";

const fetchVariantProperties = async (layer: Layer, id: string | undefined) => {
  const params = {
    version: "2.0",
    request: "GetFeature",
    service: "WFS",
    typenames: layer.wms.layers,
    outputFormat: "application/json",
    cql_filter: `${layer.variants?.uniqueProperty || "id"}='${id}'`,
    propertyName: (layer.variants?.attributes || []).flatMap((variant) => [variant.key, variant.labelKey]).join(","),
  };
  if (!id) return Promise.reject("Id is required");
  const result = await Axios.get<FeatureCollection>(layer.wms.url, { params });
  if (result.status === 200) return result.data.features.map((feature) => feature.properties);
};

export const useVariantProperties = (layer: Layer, id: string | undefined) => {
  return useQuery(
    [
      ["wfs-variants", layer.activeVariant, id],
      ...(layer.variants?.attributes || []).flatMap((variant) => [variant.key, variant.labelKey]),
    ],
    () => fetchVariantProperties(layer, id)
  );
};
