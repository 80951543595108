import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { fromArray, isArray } from "../../utils/properties.utils";

interface Props {
  className?: string;
  label: string;
  value: string | number;
}

export function LayerProperty(props: Props) {
  const { label, value } = props;

  // render the component
  return render();

  function render() {
    return (
      <Styles className={props.className}>
        <Typography component="i" className="attribute-label">
          {label}
        </Typography>

        {isArray(value) &&
          fromArray(value.toString()).map((valueItem, i) => (
            <Typography key={i} className="attribute-value">
              {valueItem}
            </Typography>
          ))}

        {!isArray(value) && <Typography className="attribute-value">{value}</Typography>}
      </Styles>
    );
  }
}

const Styles = styled.div`
  color: red;

  &:first-child {
    color: gold !important;
  }
  &::not(:first-child) {
    padding-top: ${({ theme }) => theme.spacing(1)}px;
  }
  .attribute-label {
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  .attribute-value {
    color: black;
  }
`;
