import { renderToString } from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import { divIcon, LatLngBoundsExpression, LatLngLiteral } from "leaflet";

import { MarkerIcon } from "./MarkerIcon";
import { SelectionFeature, SelectionMarker, useMapSelection } from "../../hooks/useMarker";
import { useSelectionFeatureData } from "../../hooks";
import bbox from "@turf/bbox";
import { feature, featureCollection } from "@turf/helpers";
import { useEffect, useState } from "react";

export const SelectionOnMap = () => {
  const { mapSelection, setMapSelection } = useMapSelection();
  // const [selection, setMapSelection] = useAtom(mapSelectionAtom);

  return render();

  function render() {
    if (!mapSelection) return null;

    if (mapSelection.type === "marker") {
      return (
        <MarkerFromSelection
          data={mapSelection.data}
          onChange={(data: LatLngLiteral) => setMapSelection({ type: "marker", data })}
        />
      );
    } else if (mapSelection.type === "feature") {
      return <FlyToSelection data={mapSelection.data} />;
    }
    return null;
  }
};

function MarkerFromSelection(props: { data: SelectionMarker["data"]; onChange: (value: LatLngLiteral) => void }) {
  const { data } = props;

  return render();

  function render() {
    return (
      <Marker
        position={[data.lat, data.lng]}
        draggable
        icon={divIcon({
          html: renderToString(<MarkerIcon />),
        })}
        eventHandlers={{
          dragend: (event) => {
            const latlng = event.target.getLatLng();
            props.onChange(latlng);
          },
        }}
      />
    );
  }
}

function FlyToSelection(props: { data: SelectionFeature["data"] }) {
  const { data } = props;
  const map = useMap();

  const [previousFlight, setPreviousFlight] = useState("");
  const { data: featureData } = useSelectionFeatureData(data);

  useEffect(() => {
    // if no feature data, do nothing
    if (!featureData) return;

    // if already flown, do nothing
    if (previousFlight === data.featureId) return;

    const features = featureData.features.map((f) => feature(f.geometry));
    const collection = featureCollection(features);

    const [lng1, lat1, lng2, lat2] = bbox(collection);
    const bounds: LatLngBoundsExpression = [
      [lat1, lng1],
      [lat2, lng2],
    ];
    map.flyToBounds(bounds, { animate: false });
    setPreviousFlight(data.featureId);
    return;
  }, [data.featureId, featureData, map, previousFlight]);

  return null;
}
