import { CircularProgress, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  color?: "white";
}

export const PageLoader = (props: Props) => {
  const { color } = props;
  return (
    <StyledGrid container direction="column" alignContent="center" justify="center" className={color}>
      <Grid item style={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography variant="h6" color="primary">
          Loading...
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  &.white {
    .MuiTypography-root,
    .MuiCircularProgress-root svg {
      color: ${({ theme }) => `${theme.palette ? theme.palette.common.white : "#fff"}`};
    }
  }
`;
