import { useMemo } from "react";
import { ListItem } from "@material-ui/core";
import styled from "styled-components";

import { Layer } from "../types";
import { useLayers } from "../hooks/useLayers";
import { SearchSelection } from "../pages/SearchPage";
import { fromArray, isArray } from "../utils/properties.utils";
import { match, parse } from "../utils/autosuggest-highlight.utils";

interface Props {
  feature: any; // TODO: type feature properly
  selected: boolean;
  onClick: () => void;
  layerId: NonNullable<SearchSelection["layerId"]>;
  attributes: NonNullable<SearchSelection["attributes"]>;
  query: NonNullable<SearchSelection["query"]>;
}

export function SearchResultItem(props: Props) {
  const { layers } = useLayers();
  const { layerId, feature, attributes, query } = props;

  const layer = useMemo(() => layers.find((l) => l.id === layerId), [layerId, layers]);

  return render();

  function render() {
    if (!layer) {
      console.warn("No active layer found, this should not happen");
      return null;
    }

    return (
      <StyledListItem key={feature.id} onClick={props.onClick} selected={props.selected} button>
        {attributes.map((attributeKey) => renderAttribute(layer, attributeKey))}
      </StyledListItem>
    );
  }

  function renderAttribute(layer: Layer, attributeKey: string) {
    const attribute = layer.attributes.find((a) => a.key === attributeKey);

    if (!attribute) return;

    const value = feature.properties[attributeKey];

    return (
      <div className="attribute-item" key={attributeKey}>
        <div className="attribute-label">{attribute.label}:</div>

        {/* is value an array disguised as a "[string]"? */}
        {isArray(value) && fromArray(value.toString()).map((valueItem, i) => renderAttributeValue(valueItem, i))}
        {!isArray(value) && renderAttributeValue(value)}
      </div>
    );
  }

  function renderAttributeValue(value: string, key?: string | number) {
    const matches = match(value.toString(), query, true);
    const parts = parse(value.toString(), matches);

    return (
      <div className="attribute-value" key={key}>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  }
}

const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;

  .attribute-item {
    width: 100%;

    &:not(:first-child) {
      padding-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  .attribute-label {
    font-style: italic;
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`;
