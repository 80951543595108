import { createContext, useState } from "react";
import { IConfig, Layer } from "../types";

export const LayersStateContext = createContext<[Layer[], React.Dispatch<React.SetStateAction<Layer[]>>]>([
  [],
  () => {},
]);

type LayersProviderProps = { config: IConfig; children: React.ReactNode };

export function LayersProvider({ config, children }: LayersProviderProps) {
  const [layers, setLayers] = useState(config.layers);

  return <LayersStateContext.Provider value={[layers, setLayers]}>{children}</LayersStateContext.Provider>;
}
