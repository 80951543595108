import { Fragment, useEffect, useRef } from "react";
import { Typography, Button, Drawer, IconButton, Toolbar, Divider } from "@material-ui/core";
import styled from "styled-components";
import { ChevronLeft as ChevronLeftIcon, Close as CloseIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { DomEvent } from "leaflet";

import { LayerPropertiesForMarker } from "../layer/LayerPropertiesForMarker";
import { LayerPropertiesForFeature } from "../layer/LayerPropertiesForFeature";
import { useLayers } from "../../hooks/useLayers";
import { useMapSelection } from "../../hooks/useMarker";

interface Props {
  open: boolean;
  onChangeOpen: (open: boolean) => void;
}

export const LayerPropertiesControl = (props: Props) => {
  const { activeLayers } = useLayers();
  const { open, onChangeOpen } = props;
  const { mapSelection, clearSelection } = useMapSelection();
  const drawerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // TODO: refactor into custom hook?
  useEffect(() => {
    if (drawerRef && drawerRef.current) {
      DomEvent.disableClickPropagation(drawerRef.current);
      DomEvent.disableScrollPropagation(drawerRef.current);
    }
  }, [drawerRef]);

  // TODO: refactor into custom hook?
  useEffect(() => {
    //We need the open as the ref doesn't trigger the effect, and open does...
    //disadvantage: 'open' trigger's it on each toggle
    if (buttonRef && !open && buttonRef.current) {
      DomEvent.disableClickPropagation(buttonRef.current);
    }
  }, [buttonRef, open]);

  /**
   * Functions
   */
  function handleDeleteSelection() {
    clearSelection();
    onChangeOpen(false);
  }

  return render();

  function render() {
    return (
      <Styles>
        {renderClosed()}
        {renderOpen()}
      </Styles>
    );
  }

  function renderClosed() {
    if (open) return null;

    return (
      <Button ref={buttonRef} size="small" className="toggle-drawer" onClick={() => onChangeOpen(!open)}>
        <ChevronLeftIcon />
      </Button>
    );
  }

  function renderOpen() {
    return (
      <Drawer open={open} variant="persistent" anchor="right" onClose={() => onChangeOpen(false)} ref={drawerRef}>
        <Toolbar>
          <Typography variant="h3" component="h2">
            {mapSelection?.type === "marker" && "Puntinformatie"}
            {mapSelection?.type === "feature" && "Zoekresultaat informatie"}
          </Typography>
          <div>
            <IconButton onClick={handleDeleteSelection}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => onChangeOpen(!open)}>
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>

        {mapSelection?.type === "marker" && (
          <>
            {activeLayers.map((layer, i) => (
              <Fragment key={layer.id}>
                <LayerPropertiesForMarker key={layer.id} layer={layer} selection={mapSelection.data} />
                {i + 1 < activeLayers.length && <Divider />}
              </Fragment>
            ))}
          </>
        )}

        {mapSelection?.type === "feature" && <LayerPropertiesForFeature selection={mapSelection.data} />}
      </Drawer>
    );
  }
};

const Styles = styled.div`
  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiPaper-root {
    width: 320px;
  }

  .toggle-drawer {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 40px;
    /* align-items: flex-start; */
    border-left: 1px solid rgba(0, 0, 0, 0.12);

    & > .MuiButton-label {
      padding-top: 14px;
    }
  }
`;
