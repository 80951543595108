import * as React from "react";
import { Layer } from "../../types";
import * as ogcService from "../../services/ogc.service";
import styled from "styled-components";

interface Props {
  layer: Layer;
}
export const Legend = (props: Props) => {
  const { layer } = props;
  const { legend } = layer;

  return render();

  function render() {
    return (
      <LegendStyles>
        <div className="legend-header">
          {legend.title && <div className="legendTitle">{legend.title}</div>}
          {legend.entity && (
            <div className="legendEntity">
              <span
                dangerouslySetInnerHTML={{
                  __html: legend.entity,
                }}
              />
            </div>
          )}
        </div>
        {legend.type === "wms" ? renderWms() : renderCustom()}
      </LegendStyles>
    );
  }
  function renderWms() {
    return <img src={ogcService.getLegendGraphic(layer)} alt="map-legend" />;
  }
  function renderCustom() {
    const legendItems = legend.legendItems || [];

    return (
      <CustomLegendStyles>
        {legendItems.map((legendItem, i) => {
          return (
            <div className="scaleItem" key={`scaleItem${i}`}>
              <div className="scaleColor" style={{ backgroundColor: legendItem.color }} />
              <div className="scaleRange">{legendItem.label}</div>
            </div>
          );
        })}
      </CustomLegendStyles>
    );
  }
};

const LegendStyles = styled.div`
  min-width: 120px;
  width: fit-content;

  .legend-header {
    display: flex;
    justify-content: space-between;
    color: #838383;
    padding-bottom: 10px;
    flex-direction: column;
    width: min-content;

    .legendTitle {
      font-size: 13px;
      text-transform: uppercase;
    }

    .legendEntity {
      font-size: 0.8em;
    }
  }
`;

const CustomLegendStyles = styled.div`
  display: flex;
  flex-direction: column;

  .scaleItem {
    display: flex;
    flex-direction: row;

    & + .scaleItem {
      margin-top: 3px;
    }
  }

  .scaleColor {
    width: 25px;
    height: 10px;
    margin-right: 10px;
    margin-top: 3px;
    flex: 0 0 auto;
  }

  .scaleRange {
    font-size: 1em;
    color: #838383;
  }
`;
