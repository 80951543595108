import Axios from "axios";
import json5 from "json5";
import Ajv from "ajv";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import { IConfig } from "../types";

function getConfigUrl(location: Location): string {
  const queryParams = location.search;
  const params = new URLSearchParams(queryParams);
  const config = params.get("config");

  if (!config) {
    throw new Error("A config url is required.");
  }

  // if the config ia a url, it must be the good one so return it to the application
  const urlRegex = RegExp("^https?://");
  if (urlRegex.test(config.toString())) {
    return config.toString();
  }

  // else
  // construct the url based on convention
  return `/configurations/${config}/config.json5`;
}

async function fetchConfig(location: Location) {
  const response = await Axios.get<string>(getConfigUrl(location), {
    headers: { "Cache-Control": "no-cache" },
  });
  const parsedConfig: IConfig = json5.parse(response.data);
  return validateConfig(parsedConfig);
}

function validateConfig(config: IConfig) {
  // validate config
  const ajv = new Ajv({ allErrors: true });
  const schema = require("../schemas/config.json");
  const validate = ajv.compile(schema);
  const valid = validate(config);

  if (!valid) {
    if (validate.errors)
      validate.errors.forEach((error) => {
        console.error("Config validation error", error);
      });
    throw new Error("Can not validate configuration json, see logs for more details.");
  }

  return config;
}

export const useConfig = () => {
  const location = useLocation<Location>();
  return useQuery<IConfig, Error>("config", () => fetchConfig(location), {
    staleTime: 28800000,
    retry: 1,
  });
};
