/**
 * # Credits
 * https://github.com/withvoid/melting-pot/blob/master/src/hooks/useTitle.js
 *
 * # Usage
 * function Demo () {
 *   useTitle('Hello world!');
 *
 *   return null;
 * };
 */

import React from "react";

export function useTitle(title: string) {
  React.useEffect(() => {
    document.title = title;
  }, [title]);
}
