import { Suspense, useState } from "react";
import styled from "styled-components";

import { PanelDrawer, PanelDrawerBody, PanelDrawerHeader } from "../components/drawer/PanelDrawer";
import { SearchForm } from "../components/SearchForm";
import { SearchResults } from "../components/SearchResults";
import { useMapSelection } from "../hooks/useMarker";
import { Layer } from "../types";

interface Props {}

export interface SearchSelection {
  layerId: Layer["id"] | null;
  attributes: string[];
  query: string | null;
}

export const SearchPage = (props: Props) => {
  const [searchSelection, setSearchSelection] = useState<SearchSelection>({
    layerId: null,
    attributes: [],
    query: "",
  });
  const { mapSelection, setMapSelection } = useMapSelection();

  // render the component
  return render();

  function render() {
    return (
      <Styles>
        <PanelDrawer variant="permanent" width={400}>
          <PanelDrawerHeader>Search shape</PanelDrawerHeader>

          <PanelDrawerBody>
            <SearchForm data={searchSelection} onChange={setSearchSelection} />
          </PanelDrawerBody>

          <Suspense fallback="...loading search results">
            {searchSelection.layerId && (
              <SearchResults
                layerId={searchSelection.layerId}
                attributes={searchSelection.attributes}
                query={searchSelection.query || ""}
                mapSelection={mapSelection}
                onSelect={(id, layerId) => {
                  setMapSelection({
                    type: "feature",
                    data: { featureId: id, layerId },
                  });
                }}
              />
            )}
          </Suspense>
        </PanelDrawer>
      </Styles>
    );
  }
};

const Styles = styled.div`
  .form-control {
    margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};
  }
`;
