import { useQuery, UseQueryOptions } from "react-query";
import * as OGCService from "../services/ogc.service";
import { FeatureCollection, Geometry } from "geojson";
import { AxiosError } from "axios";

export function useWFSGetFeature<P>(
  url = "/geoserver/wfs",
  params: OGCService.WFSGetFeatureParams,
  options?: UseQueryOptions<
    FeatureCollection<Geometry, P>,
    AxiosError<Error>,
    FeatureCollection<Geometry, P>,
    string | Array<string | number>
  >
) {
  return useQuery(
    ["wfs-get-feature", JSON.stringify(params)],
    () => OGCService.getWFSGetFeature<P>(url, params),
    options
  );
}
