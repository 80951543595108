import { List } from "@material-ui/core";
import { Feature } from "geojson";
import styled from "styled-components";

import { Selection } from "../hooks/useMarker";
import { useSearchResult } from "../hooks/useSearchResult";
import { Layer } from "../types";
import { SearchSelection } from "../pages/SearchPage";
import { SearchResultItem } from "./SearchResultItem";

export function SearchResults(props: {
  layerId: NonNullable<SearchSelection["layerId"]>;
  attributes: NonNullable<SearchSelection["attributes"]>;
  query: NonNullable<SearchSelection["query"]>;
  onSelect: (id: string, layerId: Layer["id"]) => void;
  mapSelection: Selection | undefined;
}) {
  const { layerId, attributes, query, mapSelection } = props;
  // const { layerId: searchLayerId, attributes, query } = data;
  // const { layers } = useLayers();

  const { data: searchResults } = useSearchResult(layerId, attributes, query);

  /**
   * Functions
   */
  function isSelected(feature: Feature) {
    if (mapSelection?.type === "feature") return feature.id === mapSelection?.data.featureId;
    else return false;
  }

  // render the component
  return render();

  function render() {
    // if (!searchLayer) return null;
    if (!searchResults) return null;

    return (
      <StyledList>
        {searchResults.features.map((feature) => {
          return (
            <SearchResultItem
              key={feature.id}
              layerId={layerId}
              attributes={attributes}
              query={query || ""}
              feature={feature}
              selected={isSelected(feature)}
              onClick={() => props.onSelect(feature.id as string, layerId)}
            />
          );
        })}
      </StyledList>
    );
  }
}

const StyledList = styled(List)`
  .MuiListItem-root {
    padding: ${({ theme }) => theme.spacing(2, 3)};
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
